// src/components/Sidebar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Logout from './Logout'; // Asegúrate de importar el componente Logout
import '../styles/Sidebar.css';

const Sidebar = () => {
  const handleLogout = () => {
    // Aquí puedes definir cómo actualizar el estado de autenticación
    // Por ejemplo, puedes redirigir a la página de login
    window.location.href = '/';
  };
  
  return (
    <div className="sidebar">
      <h2>Administración</h2>
      <ul>
        <li><Link to="/">Inicio</Link></li>
        <li><Link to="/editar-talleres">Talleres</Link></li>
        <li><Link to="/agregar-novedad">Novedades</Link></li>
        <li><Link to="/agregar-imagenes">Galeria</Link></li>
        <Logout onLogout={handleLogout} />
        {/* Agrega más enlaces según sea necesario */}
      </ul>
    </div>
  );
};

export default Sidebar;
