import React, { useEffect, useState, useRef } from 'react';
import api from '../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import '../styles/AddWorkshop.css';
import ReactMarkdown from 'react-markdown';

const AddWorkshop = () => {
  const [workshops, setWorkshops] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [editingWorkshopId, setEditingWorkshopId] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const { data } = await api.get('/workshops');
        setWorkshops(data);
      } catch (error) {
        console.error('Error al obtener los talleres:', error);
        toast.error('Error al obtener los talleres.', { autoClose: 1500 });
      }
    };

    fetchWorkshops();
  }, []);

  const handleEditorChange = ({ text }) => {
    setDescription(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !description) {
      toast.error('El título y la descripción son obligatorios.', { autoClose: 1500 });
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    }

    try {
      if (editingWorkshopId) {
        // Update existing workshop
        await api.post(`/workshops/${editingWorkshopId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { _method: 'PUT' }
        });
        toast.success('Taller actualizado correctamente.', { autoClose: 1500 });
      } else {
        // Create new workshop
        await api.post('/workshops', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        toast.success('Taller agregado correctamente.', { autoClose: 1500 });
      }

      const { data } = await api.get('/workshops');
      setWorkshops(data);
      resetForm();
    } catch (error) {
      console.error('Error al guardar el taller:', error.response ? error.response.data : error.message);
      const errorMessage = error.response?.data?.message || 'Error desconocido.';
      toast.error(`Error al guardar el taller: ${errorMessage}`, { autoClose: 1500 });
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/workshops/${id}`);
      setWorkshops(workshops.filter(workshop => workshop.id !== id));
      toast.success('Taller eliminado correctamente.', { autoClose: 1500 });
    } catch (error) {
      console.error('Error al eliminar el taller:', error);
      toast.error('Error al eliminar el taller.', { autoClose: 1500 });
    }
  };

  const handleEdit = (workshop) => {
    setTitle(workshop.title);
    setDescription(workshop.description);
    setImage(null);
    setEditingWorkshopId(workshop.id);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setImage(null);
    setEditingWorkshopId(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="add-workshop-container">
      {/* Formulario para Agregar y Actualizar Talleres */}
      <form onSubmit={handleSubmit} className="workshop-form">
        <h2>{editingWorkshopId ? 'Actualizar Taller' : 'Agregar Taller'}</h2>
        <input
          id="workshop-title"
          name="title"
          type="text"
          placeholder="Título"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <MarkdownEditor
          value={description}
          onChange={handleEditorChange}
          renderHTML={text => <ReactMarkdown>{text}</ReactMarkdown>}
        />
        <input
          id="workshop-image"
          name="image"
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
          ref={fileInputRef}
        />
        <button type="submit" className="submit-button">
          {editingWorkshopId ? 'Actualizar Taller' : 'Agregar Taller'}
        </button>
        {editingWorkshopId && <button type="button" onClick={resetForm}>Cancelar</button>}
      </form>

      {/* Lista de Talleres */}
      <div className="workshop-list">
        {workshops.map(workshop => (
          <div key={workshop.id} className="workshop-item">
            {workshop.image_path ? (
              <img
                src={`https://api2.fundacion360.com.ar/storage/${workshop.image_path.replace('public/', '')}`}
                alt={workshop.title}
                onError={(e) => e.target.src = 'https://via.placeholder.com/100'}
              />
            ) : (
              <img
                src="https://via.placeholder.com/100"
                alt="Placeholder"
              />
            )}
            <div className="workshop-content">
              <h2>{workshop.title}</h2>
              <ReactMarkdown>{workshop.description}</ReactMarkdown>
            </div>
            <div className="workshop-actions">
              <button className="edit-button" onClick={() => handleEdit(workshop)}>Editar</button>
              <button className="delete-button" onClick={() => handleDelete(workshop.id)}>Eliminar</button>
            </div>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddWorkshop;
