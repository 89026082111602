import React from 'react';
import api from '../services/api'; // Asegúrate de que la URL de la API sea correcta

const Logout = ({ onLogout }) => {
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        // El token no existe, ya estamos deslogueados
        if (onLogout) {
          onLogout(); // Llama a la función para actualizar el estado de autenticación
        }
        return;
      }

      // Si el token está activo, haz la solicitud de logout
      await api.post('/logout', null, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Eliminar el token del localStorage
      localStorage.removeItem('authToken');

      if (onLogout) {
        onLogout(); // Llama a la función para actualizar el estado de autenticación
      }
    } catch (err) {
      console.error('Logout error:', err);
      // Si ocurre un error, eliminar el token del localStorage
      localStorage.removeItem('authToken');
      if (onLogout) {
        onLogout(); // Llama a la función para actualizar el estado de autenticación
      }
    }
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      Logout
    </button>
  );
};

export default Logout;
