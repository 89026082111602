import React, { useEffect, useState, useRef } from 'react';
import api from '../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import ReactMarkdown from 'react-markdown';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import '../styles/AddNovedad.css';

const AddNovedad = () => {
  const [novedades, setNovedades] = useState([]);
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [subtitulo, setSubtitulo] = useState('');
  const [textoCompleto, setTextoCompleto] = useState('');
  const [imagenPrincipal, setImagenPrincipal] = useState(null);
  const [cropData, setCropData] = useState('');
  const [editingNovedadId, setEditingNovedadId] = useState(null);
  const [image, setImage] = useState(null);
  const [cropSuccess, setCropSuccess] = useState(false); // Estado para manejar el éxito del recorte
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchNovedades = async () => {
      try {
        const { data } = await api.get('/novedades');
        setNovedades(data);
      } catch (error) {
        console.error('Error al obtener las novedades:', error);
        toast.error('Error al obtener las novedades.', { autoClose: 1500 });
      }
    };

    fetchNovedades();
  }, []);

  const handleEditorChange = ({ text }) => {
    setDescripcion(text);
  };

  const handleTextoCompletoChange = ({ text }) => {
    setTextoCompleto(text);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      setImagenPrincipal(file);
      setCropSuccess(false); // Restablecer el estado de éxito del recorte
    }
  };

  const handleCrop = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      croppedCanvas.toBlob((blob) => {
        const file = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
        setImagenPrincipal(file);
        setCropSuccess(true); // Éxito del recorte
        toast.success('Imagen recortada con éxito.', { autoClose: 1500 });
      }, 'image/jpeg');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!titulo || !descripcion) {
      toast.error('El título y la descripción son obligatorios.', { autoClose: 1500 });
      return;
    }
  
    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('descripcion', descripcion);
    formData.append('subtitulo', subtitulo);
    formData.append('texto_completo', textoCompleto);
  
    if (imagenPrincipal) {
      formData.append('imagen_principal', imagenPrincipal);
    } else if (editingNovedadId) {
      // Include the current image URL or a placeholder if no new image is uploaded
      const currentNovedad = novedades.find(n => n.id === editingNovedadId);
      if (currentNovedad && currentNovedad.imagen_principal) {
        formData.append('imagen_principal', currentNovedad.imagen_principal);
      }
    }
  
    try {
      if (editingNovedadId) {
        await api.post(`/novedades/${editingNovedadId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { _method: 'PUT' }
        });
        toast.success('Novedad actualizada correctamente.', { autoClose: 1500 });
      } else {
        await api.post('/novedades', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        toast.success('Novedad agregada correctamente.', { autoClose: 1500 });
      }
  
      const { data } = await api.get('/novedades');
      setNovedades(data);
      resetForm();
    } catch (error) {
      console.error('Error al guardar la novedad:', error.response ? error.response.data : error.message);
      const errorMessage = error.response?.data?.message || 'Error desconocido.';
      toast.error(`Error al guardar la novedad: ${errorMessage}`, { autoClose: 1500 });
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/novedades/${id}`);
      setNovedades(novedades.filter(novedad => novedad.id !== id));
      toast.success('Novedad eliminada correctamente.', { autoClose: 1500 });
    } catch (error) {
      console.error('Error al eliminar la novedad:', error);
      toast.error('Error al eliminar la novedad.', { autoClose: 1500 });
    }
  };

  const handleEdit = (novedad) => {
    setTitulo(novedad.titulo);
    setDescripcion(novedad.descripcion);
    setImagenPrincipal(null);
    setSubtitulo(novedad.subtitulo || '');
    setTextoCompleto(novedad.texto_completo || '');
    setEditingNovedadId(novedad.id);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toggleShowMore = (id) => {
    const updatedNovedades = novedades.map((novedad) => {
      if (novedad.id === id) {
        return { ...novedad, showMore: !novedad.showMore };
      }
      return novedad;
    });
    setNovedades(updatedNovedades);
  };

  const resetForm = () => {
    setTitulo('');
    setDescripcion('');
    setImagenPrincipal(null);
    setSubtitulo('');
    setTextoCompleto('');
    setEditingNovedadId(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImage(null);
    setCropData('');
    setCropSuccess(false);
  };
  const handleShowMore = (id) => {
    const updatedNovedades = novedades.map((novedad) => {
      if (novedad.id === id) {
        return { ...novedad, showMore: !novedad.showMore };
      }
      return novedad;
    });
    setNovedades(updatedNovedades);
  };

  return (
    <div className="add-novedad-wrapper">
      <div className="add-novedad-container">
        <form onSubmit={handleSubmit} className="novedad-form">
          <div className='act-nov'>
            <h2>{editingNovedadId ? 'Actualizar Novedad' : 'Agregar Novedad'}</h2>
          </div>
          
          <input
            id="novedad-titulo"
            name="titulo"
            type="text"
            placeholder="Título de su novedad..."
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            required
          />
          <MarkdownEditor
            value={descripcion}
            onChange={handleEditorChange}
            renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
            placeholder="Descripción breve de su novedad para mostrar en la tarjeta principal"
          />
          <input
            className='agregar-archivo'
            id="novedad-imagen-principal"
            name="imagen_principal"
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
          />
          {image && (
            <div className="cropper-container">
              <Cropper
                src={image}
                style={{ height: 400, width: '100%' }}
                initialAspectRatio={1}
                aspectRatio={16 / 9}
                guides={false}
                ref={cropperRef}
              />
              <button type="button" onClick={handleCrop} disabled={cropSuccess}>
                Recortar Imagen
              </button>
            </div>
          )}
          <h3>Subtitulo: </h3>
          <input
            id="novedad-subtitulo"
            name="subtitulo"
            type="text"
            value={subtitulo}
            onChange={(e) => setSubtitulo(e.target.value)}
            placeholder="Subtitulo de su novedad..."
            required
          />
          <MarkdownEditor
            value={textoCompleto}
            onChange={handleTextoCompletoChange}
            renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
            placeholder="Texto completo"
          />
          <button type="submit" className="submit-button">
            {editingNovedadId ? 'Actualizar Novedad' : 'Agregar Novedad'}
          </button>
          {editingNovedadId && <button type="button" onClick={resetForm}>Cancelar</button>}
        </form>

        <div className="novedad-list">{novedades.map((novedad) => (
            <div key={novedad.id} className="novedad-item">
            <img
              src={`https://api2.fundacion360.com.ar/storage/${novedad.imagen_principal}`}
              alt={novedad.titulo}
              onError={(e) => (e.target.src = 'https://via.placeholder.com/100')}
            />
            <div className="novedad-content">
              <h2>{novedad.titulo}</h2>
              <p className="novedad-date">
                {new Date(novedad.created_at).toLocaleDateString()}{' '}
                {new Date(novedad.created_at).toLocaleTimeString()}
              </p>
              <p className={`novedad-description ${novedad.showMore ? 'expanded' : ''}`}>
                {novedad.descripcion}
              </p>
              {novedad.showMore && (
                <>
                  <h3>{novedad.subtitulo}</h3> {/* Mostrar subtítulo */}
                  <ReactMarkdown>{novedad.texto_completo}</ReactMarkdown>
                </>
              )}
              <div className="action-buttons">
                <button className="edit-button" onClick={() => handleEdit(novedad)}>Editar</button>
                <button className="delete-button" onClick={() => handleDelete(novedad.id)}>Eliminar</button>
              </div>
              {novedad.texto_completo && (
                <button className="show-more-button" onClick={() => handleShowMore(novedad.id)}>
                  {novedad.showMore ? 'Mostrar menos' : 'Mostrar más'}
                </button>
              )}
            </div>
          </div>
          
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddNovedad;
