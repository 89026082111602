import React from 'react';
import '../styles/TopBar.css'; // Importa los estilos
import logofund from '../images/logo.png';
const Topbar = () => {
  
  return (
    <div className="topbar">
      <img src={logofund} alt="Logofdd" className="logofund-back" /> {/* Muestro la imagen del logotipo de la fundación */}
      <h1 className="title">Administracion</h1>
    </div>
  );
};

export default Topbar;
