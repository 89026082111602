import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Apps from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import ReactGA from 'react-ga4';

//main prueba subir
const root = ReactDOM.createRoot(document.getElementById('root'));

// Reemplaza '123' con tu ID de medición real
ReactGA.initialize('123');
root.render(
  <React.StrictMode>
    <Router>
    <Apps />
    </Router>
  </React.StrictMode>
);

