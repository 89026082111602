import React, { useState } from 'react';
import api from '../services/api';
import '../styles/Login.css';
import logo from '../images/logo.png'; // Asegúrate de que el logo esté en la ruta correcta

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/login', { username, password });
      if (response.status === 200) {
        localStorage.setItem('authToken', response.data.token);
        onLogin();
      }
    } catch (err) {
      setError('Credenciales incorrectas');
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Logo Fundación" className="login-logo" />
      <h2 className='title-inicio-sesion'>Iniciar sesión</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="text"
          placeholder="Usuario"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="login-input"
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="login-input"
        />
        <button type="submit" className="login-button">Iniciar sesión</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
