import React, {useState, useEffect} from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AddWorkshop from './components/AddWorkshop';
import Home from './components/Home';
import Layout from './components/Layout'; // Importa el componente consolidado
import AddNovedad from './components/AddNovedad';
import AddImage from './components/AddImages';
import Login from './components/Login';
import './styles/App.css';
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    navigate('/');
  };

  return (
    <div>
      {isAuthenticated ? (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/editar-talleres" element={<AddWorkshop />} />
            <Route path="/agregar-novedad" element={<AddNovedad />} />
            <Route path="/agregar-imagenes" element={<AddImage />} />
            {/* Agrega otras rutas aquí */}
          </Routes>
        </Layout>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;