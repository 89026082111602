import React, { useState, useEffect, useRef } from 'react';
import api from '../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AddImages.css';

const AddImage = () => {
  const [media, setMedia] = useState([]);
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchMedia();
  }, []);

  const fetchMedia = async () => {
    try {
      const { data } = await api.get('/images');
      setMedia(data);
    } catch (error) {
      console.error('Error al obtener los archivos:', error);
      toast.error('Error al obtener los archivos.', { autoClose: 1500 });
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);

    const newPreviews = selectedFiles.map(file => URL.createObjectURL(file));
    setPreviews(newPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.error('Por favor, seleccione archivos para subir.', { autoClose: 1500 });
      return;
    }

    const formData = new FormData();
    files.forEach(file => formData.append('images[]', file));

    try {
      await api.post('/images', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success('Archivos subidos correctamente.', { autoClose: 1500 });
      setFiles([]);
      setPreviews([]);
      fetchMedia();
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error al subir los archivos:', error.response ? error.response.data : error.message);
      toast.error('Error al subir los archivos.', { autoClose: 1500 });
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/images/${id}`);
      setMedia(media.filter(item => item.id !== id));
      toast.success('Archivo eliminado correctamente.', { autoClose: 1500 });
    } catch (error) {
      console.error('Error al eliminar el archivo:', error.response ? error.response.data : error.message);
      toast.error('Error al eliminar el archivo.', { autoClose: 1500 });
    }
  };

  return (
    <div className="add-image-wrapper">
      <div className="add-image-container">
        <form onSubmit={handleSubmit} className="image-form">
          <h2>Agregar Imágenes</h2>
          <input
            id="image-file"
            name="images"
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
            required
          />
          {previews.map((preview, index) => {
            const isVideo = preview.endsWith('.mp4') || preview.endsWith('.mov');
            return (
              <div key={index} className="preview-wrapper">
                {isVideo ? (
                  <video src={preview} controls className="image-preview" />
                ) : (
                  <img src={preview} alt={`Preview ${index}`} className="image-preview" />
                )}
              </div>
            );
          })}
          <button type="submit" className="submit-button">Agregar Imágenes</button>
        </form>

        <div className="image-list">
          {media.map(item => {
            const isVideo = item.path.endsWith('.mp4') || item.path.endsWith('.mov');
            const uploadDate = new Date(item.created_at).toLocaleDateString(); // Convertir la fecha al formato legible
            const uploadTime = new Date(item.created_at).toLocaleTimeString();
            return (
              <div key={item.id} className="image-item">
                {isVideo ? (
                  <video
                    src={`https://api2.fundacion360.com.ar/storage/${item.path}`}
                    controls
                    className="uploaded-image"
                    onError={(e) => e.target.src = 'https://via.placeholder.com/150'}
                  />
                ) : (
                  <img
                    src={`https://api2.fundacion360.com.ar/storage/${item.path}`}
                    alt="Uploaded"
                    className="uploaded-image"
                    onError={(e) => e.target.src = 'https://via.placeholder.com/150'}
                  />
                )}
                <div className="image-info">
                  <p>Fecha: {uploadDate}</p> {/* Mostrar la fecha de subida */}
                  <p>Hora: {uploadTime}</p>
                </div>
                <button className="delete-button" onClick={() => handleDelete(item.id)}>Eliminar</button>
              </div>
            );
          })}
        </div>


        <ToastContainer />
      </div>
    </div>
  );
};

export default AddImage;
