import '../styles/Home.css';
import exampleImg from '../images/fomento1.jpeg'
import exampleImg2 from '../images/curso4.jpeg'
const Home = () => {

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Bienvenido/a a el Panel de Administracion</h1>
        <p>Desde aquí podra gestionar varios aspectos de su web, como novedades, imagenes o Talleres</p>
      </header>
      <section className="home-content">
        <img className="home-img" src={exampleImg} alt="imgfront1" />
        <img className="home-img" src={exampleImg2} alt="imgfront2" />
      </section>
    </div>
  );
};

export default Home;
